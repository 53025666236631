import { useLocation, useNavigate } from "react-router-dom";
import ActivityMonitor from "../../../Components/ActivityMonitor/ActivityMonitor";
import JuraLiveSlider from "../../../Components/JuraLiveSlider/JuraLiveSlider";
import TopMenu from "../TopMenu/TopMenu";
import Scaffold from "./Scaffold";
import AppRoutes from "../../AppRoutes";

function DefaultScaffold({
  body,
  timeoutEnabled = true,
  showJuraLiveBanner = true,
  pageNavigation,
}: {
  body?: JSX.Element;
  timeoutEnabled?: boolean;
  showJuraLiveBanner?: boolean;
  pageNavigation?: JSX.Element;
}): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    // After a period of inactivity, return to the front page

    <ActivityMonitor
      onTimeout={() => {
        if (timeoutEnabled && location.pathname != AppRoutes.Front) {
          navigate(AppRoutes.Front);
        }
      }}
      //TODO: SET THIS TIMEOUT CORRECT FOR RELEASE
      timeoutMs={timeoutEnabled ? 60000 : -1}
    >
      <Scaffold
        topMenu={<TopMenu />}
        body={body}
        juraLive={
          showJuraLiveBanner ? (
            <div className="flex flex-col items-center justify-center h-full">
              <div className="pointer-events-auto">
                <JuraLiveSlider />
              </div>
            </div>
          ) : undefined
        }
        pageNavigation={pageNavigation}
      />
    </ActivityMonitor>
  );
}

export default DefaultScaffold;
