import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";
import Image3 from "./Images/cafe-creme.png";
import Image2 from "./Images//Latte-macchiato 1.png";
import Image1 from "./Images/Z10_Diamond_Black_EA_15349_hero 1.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
import ProjectIcon from "../../../Components/ProjectIcon/ProjectIcon";
function CoffeeQualityPage() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  var body = (
    <div className="grid" style={{ gridTemplateColumns: "8rem 1fr 8rem" }}>
      <div></div>
      <div>
        <div className="text-headline-1 text-jura-brown mb-5 relative">
          {t("Quality.Title")}
        </div>
        <div className="text-body text-black">{t("Quality.Text")}</div>
        <div className="flex flex-col py-20">
          <img className="w-full" src={Image1}></img>
          <div className="h-10"></div>
          <div
            className="grid"
            style={{ gridTemplateColumns: "1fr 2.5rem 1fr" }}
          >
            <img className="w-full" src={Image2}></img>
            <div></div>
            <img className="w-full" src={Image3}></img>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <DefaultScaffold
      body={body}
      pageNavigation={
        <div
          className=" mt-[8.5rem] h-full  w-[8rem] flex flex-row justify-end pr-8"
          style={{ pointerEvents: "all" }}
        >
          <div className="h-16" onClick={() => navigate(AppRoutes.About)}>
            <ProjectIcon icon={"chevron_left"} />
          </div>
        </div>
      }
    />
  );
}

export default CoffeeQualityPage;
