import { useNavigate } from "react-router-dom";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import AppRoutes from "../AppRoutes";

function ProductsPage() {
  const navigate = useNavigate();
  var body = (
    <div className="px-32 flex flex-col h-full pb-20">
      <div className="text-headline-1 text-jura-brown mb-5">
        Produkte Haushalt
      </div>
      <div className="text-body text-black">
        Wer sich für einen unserer Kaffeespezialitäten-Vollautomaten ausspricht,
        entscheidet sich für die höchste Qualität von der Beratung über das
        Produkt bis hin zu den Serviceleistungen.
      </div>
      <div className="flex-1 grid grid-cols-3 gap-5 mt-5">
        <ProductCategoryCard
          title="Kaffeevollautomaten"
          text="asdf"
          imageSrc="asdf"
          onClick={() => {
            navigate(AppRoutes.Products_CoffeeMachines);
          }}
        />
        <ProductCategoryCard title="Zubehör" text="asdf" imageSrc="asdf" />
        <ProductCategoryCard
          title="Pflegeprodukte"
          text="asdf"
          imageSrc="asdf"
        />
      </div>
    </div>
  );

  return <DefaultScaffold body={body} timeoutEnabled={false} />;
}

function ProductCategoryCard({
  title,
  text,
  imageSrc,
  onClick,
}: {
  title: string;
  text: string;
  imageSrc: string;
  onClick?: () => void;
}) {
  return (
    <div
      className=" grid cursor-pointer"
      style={{ gridTemplateRows: "60% 40%" }}
      onClick={() => onClick?.()}
    >
      <div className="bg-red-200">{imageSrc}</div>
      <div className="bg-jura-grey-20 p-6">
        <div className=" font-arial-bold text-headline-5 text-black">
          {title}
        </div>
        <div className="font-arial">{text}</div>
      </div>
    </div>
  );
}

export default ProductsPage;
