import { useTranslation } from "react-i18next";
import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";

import Image1 from "./Images/1940er 1.png";
import Image2 from "./Images/1950er 1.png";
import Image3 from "./Images/archiv_inserate 1.png";
import ProjectIcon from "../../../Components/ProjectIcon/ProjectIcon";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../AppRoutes";

function HistoryPage() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  var body = (
    <div className="grid" style={{ gridTemplateColumns: "8rem 1fr 8rem" }}>
      <div></div>
      <div>
        <div className="text-headline-1 text-jura-brown mb-5 relative">
          {t("History.Title")}
        </div>
        <div className="text-body text-black">{t("History.Text")}</div>

        <div className="flex flex-col py-20">
          <img className="w-full" src={Image1}></img>
          <div className="h-14"></div>
          <img className="w-full" src={Image2}></img>
          <div className="h-14"></div>
          <img className="w-full" src={Image3}></img>
        </div>
      </div>
    </div>
  );

  return (
    <DefaultScaffold
      body={body}
      pageNavigation={
        <div
          className=" mt-[8.5rem] h-full  w-[8rem] flex flex-row justify-end pr-8"
          style={{ pointerEvents: "all" }}
        >
          <div className="h-16" onClick={() => navigate(AppRoutes.About)}>
            <ProjectIcon icon={"chevron_left"} />
          </div>
        </div>
      }
    />
  );
}

export default HistoryPage;
