import { useNavigate } from "react-router-dom";
import Image from "./roger_image.png";
import AppRoutes from "../AppRoutes";

function RogerImage(): JSX.Element {
  const navigate = useNavigate();
  const navigateToRogerPage = () => {
    navigate(AppRoutes.About_RogerFederer);
  };
  return (
    <img
      src={Image}
      className="h-full cursor-pointer"
      onClick={navigateToRogerPage}
    />
  );
}

export default RogerImage;
