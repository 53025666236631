export interface ScaffoldProps {
  topMenu?: JSX.Element;
  body?: JSX.Element;
  juraLive?: JSX.Element;
  pageNavigation?: JSX.Element;
}

function Scaffold({
  topMenu,
  body,
  juraLive,
  pageNavigation,
}: ScaffoldProps): JSX.Element {
  return (
    <div className="relative w-full h-full">
      {/* Body and Menu */}
      <div className="h-full w-full flex flex-col">
        <div className="w-full">{topMenu}</div>
        <div className="w-full flex-1 relative">
          <div className="absolute overflow-y-auto h-full w-full">{body}</div>
        </div>
      </div>
      {/* Jura Live Banner Area */}
      <div className="fixed right-0 top-0 h-full pointer-events-none">
        {juraLive}
      </div>
      {/* Page Navigation Area */}
      <div className="fixed left-0 top-0 h-full pointer-events-none">
        {pageNavigation}
      </div>
    </div>
  );
}

export default Scaffold;
