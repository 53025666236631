import { useNavigate } from "react-router-dom";
import { useJuraLive } from "../../../JuraLive/JuraLive";
import AppRoutes from "../../AppRoutes";
import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";
import Iframe from "react-iframe";
import { AppDependencyContext } from "../../..";
import { useContext, useEffect } from "react";
import JuraLiveCall from "../../../JuraLive/JuraLiveCall";

function CallPage(): JSX.Element {
  const dependencies = useContext(AppDependencyContext);
  const juraLiveState = useJuraLive(dependencies!.juraLive);

  const navigate = useNavigate();

  const leaveCall = () => {
    navigate(AppRoutes.JuraLive);
  };

  const body = (
    <div className="h-full w-full">
      {juraLiveState.isOnline == false &&
      juraLiveState.connectionUrl != undefined ? (
        <>
          <div>Keine Verbindung zu JURA LIVE Möglich</div>
        </>
      ) : (
        <div
          className="grid h-full py-20 gap-10 place-items-center px-10"
          style={{ gridTemplateRows: "minmax(0,1fr) auto" }}
        >
          <div className="h-full w-full">
            <JuraLiveCall
              url={juraLiveState.connectionUrl!}
              onCallEnded={leaveCall}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <DefaultScaffold
      body={body}
      showJuraLiveBanner={false}
      timeoutEnabled={false}
    />
  );
}

export default CallPage;
