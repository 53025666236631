import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Video from "./../jura_live_video.mp4";
import AppRoutes from "../../AppRoutes";
import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";
import { useTranslation } from "react-i18next";

function CallDelayPage(): JSX.Element {
  const [countdown, setCountdown] = useState<number>(10);
  const countdownRef = useRef<number>(10);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  useEffect(() => {
    const interval = setInterval(() => {
      console.log(countdownRef.current);
      if (countdownRef.current > 0) {
        countdownRef.current -= 1;
        setCountdown(countdownRef.current);
      } else {
        clearInterval(interval);
        navigate(AppRoutes.JuraLive_Call);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const body = (
    <div className="px-40 pt-40 grid grid-cols-2">
      <div>
        <div className="text-headline-3 text-black mb-20">
          {t("Call.YouAreBeingConnected")}
        </div>
        <div className="text-body text-jura-grey-60 mb-40">
          {t("Call.YourCallWillBegin").replace("10", countdown.toString())}
        </div>
        {/* Button */}
        <div
          className="h-20 w-80 bg-jura-dark-red text-white grid place-items-center rounded-lg"
          onClick={() => navigate(AppRoutes.JuraLive)}
        >
          {t("Call.Cancel")}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center ">
        {/* Video */}

        <video src={Video} autoPlay muted loop className="w-full mx-auto" />
      </div>
    </div>
  );
  return (
    <DefaultScaffold
      body={body}
      showJuraLiveBanner={false}
      timeoutEnabled={false}
    />
  );
}

export default CallDelayPage;
