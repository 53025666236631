import { useState } from "react";

function Hamburger({ state }: { state: HamburgerState }): JSX.Element {
  return (
    <div
      className={[
        "h-8 w-12 relative",
        state == HamburgerState.Hamburger ? "" : "",
      ].join(" ")}
    >
      <div
        className={[
          "w-full h-1 bg-jura-grey-80 transition-all absolute bounce",
          state == HamburgerState.Hamburger
            ? "top-0 translate-y-0 rotate-0"
            : "top-1/2 -translate-y-1/2 -rotate-45",
        ].join(" ")}
      ></div>
      <div
        className={[
          "w-full h-1 bg-jura-grey-80 transition-all absolute top-1/2 -translate-y-1/2 ",
          state == HamburgerState.Hamburger ? "opacity-100" : " opacity-0",
        ].join(" ")}
      ></div>
      <div
        className={[
          "w-full h-1 bg-jura-grey-80 transition-all absolute ",
          state == HamburgerState.Hamburger
            ? "bottom-0 translate-y-0 rotate-0"
            : "bottom-1/2 translate-y-1/2 rotate-45",
        ].join(" ")}
      ></div>
    </div>
  );
}

export enum HamburgerState {
  Hamburger,
  Cross,
}

export default Hamburger;
