enum AppRoutes {
  // FRONT
  Front = "/front",

  // ABOUT
  About = "/about",
  About_History = "/about/history",
  About_RogerFederer = "/about/roger-federer",
  About_CoffeeQuality = "/about/coffee-quality",
  About_Design = "/about/design",
  About_Service = "/about/service",
  About_Sustainability = "/about/sustainability",

  // PRODUCTS
  Products = "/products",
  Products_CoffeeMachines = "/products/coffee-machines",
  Products_Product = "/products/product",

  // JURA LIVE
  JuraLive = "/jura-live",
  JuraLive_CallOptions = "/jura-live/call-options/",
  JuraLive_CallDelay = "/jura-live/call-delay",
  JuraLive_Call = "/jura-live/call",

  // JOE
  Joe = "/joe",
  Joe_Download = "/joe/download",

  // COFFEE EXPERT QUIZ
  CoffeeExpertQuiz = "/coffee-expert-quiz",

  // TECHNOLOGIES

  Technologies = "/technologies",

  //TEST
  Test1 = "/test1",
  Test2 = "/test2",
}

export default AppRoutes;
