import "./i18n/init";
import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ISiteKiosk, SiteKiosk } from "./SiteKiosk/SiteKiosk";
import MockSiteKiosk from "./Mocks/MockSiteKiosk";
import { JuraLive } from "./JuraLive/JuraLive";
import { initi18n } from "./i18n/init";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export interface AppDependencies {
  siteKiosk: ISiteKiosk;
  juraLive: JuraLive;
}
export const AppDependencyContext = createContext<AppDependencies | null>(null);

// Application entry point, wrapped in a promise because some dependencies load asynchronously!
new Promise(async () => {
  //Env
  var juraLiveBaseUrl = process.env.REACT_APP_JURA_LIVE_BASEURL;
  var juraLiveAsiaUrl = process.env.REACT_APP_JURA_LIVE_BASEURL_ASIA || "";

  if (juraLiveBaseUrl == undefined) {
    alert("Missing Environmentvariable REACT_APP_JURA_LIVE_BASEURL");
    throw "Missing Environmentvariable REACT_APP_JURA_LIVE_BASEURL";
  }

  //Create SiteKiosk dependency
  let siteKiosk: ISiteKiosk;
  try {
    await SiteKiosk.init();


    siteKiosk = new SiteKiosk();
  } catch (e) {
    alert(
      "SiteKiosk environment not detected, using dummy values for jura live!"
    );
    siteKiosk = new MockSiteKiosk();
  }

  var language = siteKiosk.loadConfiguration()?.language ?? "en";

  var country = siteKiosk.loadConfiguration()?.country ?? "";

  //init i18n
  initi18n(language);

  //Create JuraLive dependency
  const juraLive = new JuraLive(30000, siteKiosk, (country.toLowerCase() == "au" || country.toLowerCase() == "cn") ? juraLiveAsiaUrl : juraLiveBaseUrl );

  //Provide all app dependencies in a context
  const dependencies: AppDependencies = {
    siteKiosk: siteKiosk,
    juraLive: juraLive,
  };

  root.render(
    <AppDependencyContext.Provider value={dependencies}>
      <App />
    </AppDependencyContext.Provider>
  );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
