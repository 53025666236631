import { useNavigate } from "react-router-dom";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import AppRoutes from "../AppRoutes";
import ProjectIcon from "../../Components/ProjectIcon/ProjectIcon";
import Video from "./jura_live_video.mp4";

import { useJuraLive } from "../../JuraLive/JuraLive";
import { useContext } from "react";
import { AppDependencyContext } from "../..";
import { useTranslation } from "react-i18next";

function JuraLivePage() {
  const navigate = useNavigate();
  const context = useContext(AppDependencyContext);
  const juraLive = useJuraLive(context!.juraLive);
  const [t, i18n] = useTranslation();

  var body = (
    <div
      className="px-32 grid h-full"
      style={{ gridTemplateRows: "auto minmax(0,1fr)" }}
    >
      {/* Top */}
      <div className="">
        <div className="text-headline-1 pt-10 text-jura-brown  mb-20">
          {t("JuraLive.Title")}
        </div>
        {/* Steps */}
        <div className="grid grid-cols-4">
          {/* 1 */}
          <div className="flex flex-row">
            {/* Icon */}
            <div className="h-12 w-12 rounded-full bg-jura-grey-40 flex justify-center items-center p-3 mr-[1rem]">
              <ProjectIcon icon={"touch"} />
            </div>
            {/* Text */}
            <div className="flex-1">
              <div className="max-w-[20rem]  text-headline-5 text-jura-grey-80 font-bold">
                {t("JuraLive.SelectLive").substring(
                  0,
                  t("JuraLive.SelectLive").indexOf("»")
                )}
                <span
                  className={[
                    juraLive.isOnline
                      ? "text-jura-live-green cursor-pointer"
                      : "",
                  ].join(" ")}
                  onClick={
                    juraLive.isOnline
                      ? () => navigate(AppRoutes.JuraLive_CallOptions)
                      : undefined
                  }
                >
                  {t("JuraLive.SelectLive").substring(
                    t("JuraLive.SelectLive").indexOf("«") + 1,
                    t("JuraLive.SelectLive").indexOf("»")
                  )}
                </span>

                {t("JuraLive.SelectLive").substring(
                  t("JuraLive.SelectLive").indexOf("«") + 1
                )}
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="flex flex-row ">
            {/* Icon */}
            <div className="h-12 w-12 rounded-full bg-jura-grey-40 flex justify-center items-center p-3 mr-[1rem]">
              <ProjectIcon icon={"phone_or_message"} />
            </div>
            {/* Text */}
            <div className="flex-1">
              <div className="max-w-[15rem]  text-headline-5 text-jura-grey-80 font-bold">
                {t("JuraLive.SelectReach")}
              </div>
            </div>
          </div>
          {/* 3 */}
          <div className="flex flex-row">
            {/* Icon */}
            <div className="h-12 w-12 rounded-full bg-jura-grey-40 flex justify-center items-center p-3 mr-[1rem]">
              <ProjectIcon icon={"headset"} />
            </div>
            {/* Text */}
            <div className="flex-1">
              <div className="max-w-[20rem]  text-headline-5 text-jura-grey-80 font-bold">
                {t("JuraLive.GetConsultation")}
              </div>
              {juraLive.isOnline ? (
                <div className="text-headline-6 mt-6 text-jura-grey-80">
                  {t("JuraLive.ImageNotTransmitted")}
                </div>
              ) : (
                <div className="text-headline-6 mt-6 text-jura-red">
                  {t("JuraLive.NotAvailable")}
                </div>
              )}
            </div>
          </div>
          {/* 4 */}
          <div className="flex flex-row">
            {/* Icon */}
            <div className="h-12 w-12 rounded-full bg-jura-grey-40 flex justify-center items-center p-3 mr-[1rem]">
              <ProjectIcon icon={"shopping_cart"} />
            </div>
            {/* Text */}
            <div className="flex-1">
              <div className="max-w-[15rem] text-headline-5 text-jura-grey-80 font-bold">
                {t("JuraLive.PurchaseInStore")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Video */}
      <div className="w-full flex flex-row justify-center">
        <video src={Video} autoPlay muted loop className="h-full" />
      </div>
    </div>
  );

  return <DefaultScaffold body={body} />;
}

export default JuraLivePage;
