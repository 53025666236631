import { useNavigate } from "react-router-dom";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import AppRoutes from "../AppRoutes";
import { useTranslation } from "react-i18next";

function AboutJuraPage(): JSX.Element {
  const [t, i18n] = useTranslation();

  let body = (
    <div className="h-full w-full">
      <Header />
      <ListItem
        title={t("About.History.ListTitle")}
        subTitle={t("About.History.ListText")}
        route={AppRoutes.About_History}
      />
      <ListItem
        title={t("About.Roger.ListTitle")}
        subTitle={t("About.Roger.ListText")}
        route={AppRoutes.About_RogerFederer}
      />
      <ListItem
        title={t("About.Quality.ListTitle")}
        subTitle={t("About.Quality.ListText")}
        route={AppRoutes.About_CoffeeQuality}
      />
      <ListItem
        title={t("About.Design.ListTitle")}
        subTitle={t("About.Design.ListText")}
        route={AppRoutes.About_Design}
      />
      <ListItem
        title={t("About.Service.ListTitle")}
        subTitle={t("About.Service.ListText")}
        route={AppRoutes.About_Service}
      />
      <ListItem
        title={t("About.Sustainability.ListTitle")}
        subTitle={t("About.Sustainability.ListText")}
        route={AppRoutes.About_Sustainability}
      />
    </div>
  );
  return <DefaultScaffold body={body} />;
}

function Header() {
  const [t, i18n] = useTranslation();
  return (
    <div className="bg-white px-20 flex flex-col justify-center pt-10 pb-20">
      <div className="text-jura-brown text-headline-1"> {t("About.H1")}</div>
      <div className="mb-4"></div>
      <div className="text-black text-headline-4">{t("About.H2")}</div>
    </div>
  );
}

function ListItem({
  title,
  subTitle,
  route,
}: {
  title: string;
  subTitle: string;
  route?: string;
}) {
  const navigate = useNavigate();
  return (
    <div
      className="h-60 border-t border-jura-grey-40  bg-jura-grey-20 flex flex-row items-center px-20 cursor-pointer group hover:brightness-95"
      onClick={() => {
        if (route != undefined) {
          navigate(route);
        }
      }}
    >
      <div className="w-[35rem] text-black text-headline-3">{title}</div>
      <div className="flex-1 text-black text-body ml-20 mr-60">{subTitle}</div>
      <div className=" flex flex-row justify-center align-center">
        <div className="material-symbols-outlined group-hover:translate-x-4 transition-all text-black">
          chevron_right
        </div>
      </div>
    </div>
  );
}
export default AboutJuraPage;
