import { useNavigate } from "react-router-dom";
import Button, { GoldButton, GreyButton } from "../../Components/Button/Button";
import AppRoutes from "../AppRoutes";

interface CallToActionProps {
  title: string;
  subTitle: string;
  buttonText: string;
  buttonRoute: string;
}

function CallToAction({
  title,
  subTitle,
  buttonText,
  buttonRoute,
}: CallToActionProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div>
      <div className="text-headline-2 text-jura-brown">{title}</div>
      <div className="mb-8"></div>
      <div className=" text-headline-5 text-black font-arial">{subTitle}</div>
      <div className="mb-8"></div>
      <GreyButton
        text={buttonText}
        onClick={() => navigate(AppRoutes.CoffeeExpertQuiz)}
      />
    </div>
  );
}

export default CallToAction;
