function Button({
  text,
  className = "bg-jura-grey-60 text-white",
  onClick,
}: {
  text: string;
  className?: string;
  onClick?: () => void;
}): JSX.Element {
  return (
    <div
      className={[
        "rounded-md font-bold text-[1.25rem]  w-max px-6 py-4 cursor-pointer select-none hover:brightness-75 transition-all",
        className ?? "",
      ].join(" ")}
      onClick={onClick}
    >
      {text}
    </div>
  );
}
export default Button;

export function GreyButton({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) {
  return (
    <Button
      className="bg-jura-grey-60 text-white"
      text={text}
      onClick={onClick}
    />
  );
}
export function GoldButton({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) {
  return (
    <Button className="bg-jura-gold text-white" text={text} onClick={onClick} />
  );
}
export function GreenButton({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) {
  return (
    <Button
      className="bg-jura-live-green text-white"
      text={text}
      onClick={onClick}
    />
  );
}
