import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import SV from "./sv.json";
import RU from "./ru.json";
import CS from "./cs.json";
import ET from "./et.json";
import LT from "./lt.json";
import DA from "./da.json";
import FI from "./fi.json";
import PL from "./pl.json";
import NL from "./nl.json";
import FR from "./fr.json";
import EN from "./en.json";
import DE from "./de.json";
import LV from "./lv.json";


export function initi18n(prefferedLanguage: string) {
  prefferedLanguage = prefferedLanguage.toLowerCase();

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
      {
        resources: {
          de: {
            translation: DE,
          },
          en: {
            translation: EN,
          },
          fr: {
            translation: FR,
          },
          nl: {
            translation: NL,
          },
          pl: {
            translation: PL,
          },
          sv: {
            translation: SV,
          },
          ru: {
            translation: RU,
          },
          cs: {
            translation: CS,
          },
          et: {
            translation: ET,
          },
          lt: {
            translation: LT,
          },
          da: {
            translation: DA,
          },
          fi: {
            translation: FI,
          },
          lv: {
            translation: LV,
          }
        },
        lng: prefferedLanguage,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
      },
      (a, b) => {
        //check errors here
      }
    );
}
