import { useNavigate } from "react-router-dom";
import { GreyButton } from "../../Components/Button/Button";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import Banner from "./joe_banner.png";
import Video from "./joe_video.mp4";
import AppRoutes from "../AppRoutes";
import { useTranslation } from "react-i18next";
import Juraify from "../../Components/Juraify/Juraify";

function JoePage(): JSX.Element {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  let body = (
    <div
      className="grid h-full"
      style={{ gridTemplateRows: "minmax(0,1fr) auto" }}
    >
      <div>
        <video autoPlay muted className="w-full h-full" src={"videos/" + t("Joe.Video") + ".mp4"}></video>
        {/* <img className="h-full w-full object-cover" src={Banner} /> */}
      </div>
      <div className="px-20 py-10">
        <div className=" text-headline-3 text-jura-brown">
          <Juraify text={t("Joe.Title")} />
        </div>
        <div className=" text-body text-jura-grey-80 pt-5">
          <Juraify text={t("Joe.Text")} />
        </div>
        <div className="pt-5">
          <GreyButton
            text={t("Joe.DownloadApp")}
            onClick={() => {
              navigate(AppRoutes.Joe_Download);
            }}
          />
        </div>
      </div>
    </div>
  );
  return <DefaultScaffold body={body} />;
}

export default JoePage;
