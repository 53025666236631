import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";
import IosBadge from "./ios_badge.png";
import AndroidBadge from "./android_badge.png";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import Juraify from "../../../Components/Juraify/Juraify";

function JoeDownloadPage(): JSX.Element {
  const [t, i18n] = useTranslation();

  let body = (
    <div
      className="grid h-full"
      style={{ gridTemplateRows: "auto minmax(0,1fr)" }}
    >
      {/* Top */}
      <div className="flex flex-col items-center pb-10">
        <div className=" text-headline-2 text-jura-brown mb-10">
          <Juraify text={t("JoeDownload.Download")} />
        </div>
        <div className="text-black text-body font-arial-bold">
          {t("JoeDownload.Requirements")}
        </div>
        <div className="text-black text-body">{t("JoeDownload.Android")}</div>
        <div className="text-black text-body">{t("JoeDownload.Ios")}</div>
        <div className="text-black text-body">{t("JoeDownload.Bluetooth")}</div>
        <div className="text-black text-body">{t("JoeDownload.Watch")}</div>
      </div>
      {/* Bottom */}
      <div
        className="border-t grid"
        style={{ gridTemplateColumns: "minmax(0,1fr) auto minmax(0,1fr)" }}
      >
        {/* IOS */}
        <div className="flex flex-col items-end px-40 pt-20">
          <div className="w-60 mb-16">
            <img className="w-full" src={IosBadge} />
          </div>
          <div className="w-60 h-60">
            <QRCode
              className="h-full w-full"
              value="https://apps.apple.com/ch/app/j-o-e/id1364370646"
            />
          </div>
        </div>
        {/* Vertical Divider */}
        <div className="border-r h-full"></div>
        {/* Android */}
        <div className="flex flex-col items-start px-40 pt-20">
          <div className="w-60 mb-16">
            <img className="w-full" src={AndroidBadge} />
          </div>
          <div className="w-60 h-60 ">
            <QRCode
              className="h-full w-full"
              value="https://play.google.com/store/apps/details?id=ch.toptronic.joe"
            />
          </div>
        </div>
      </div>
    </div>
  );
  return <DefaultScaffold body={body} />;
}

export default JoeDownloadPage;
