import { useState } from "react";
import useDetectClickOutside from "../../Components/ClickOutsideDetector/ClickOutsideDetector";
import ClickOutsideDetector from "../../Components/ClickOutsideDetector/ClickOutsideDetector";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import TechnologyBackground from "./technologies_bg.png";
import LoadingPoster from "./loading_poster.png";

import { useTranslation } from "react-i18next";

enum Videos {
  Brewing,
  Grinder,
  MilkSystem,
  Preparation,
  WaterSystem,
}

interface VideoPopupProps {
  src: string;
  title: string;
}
function TechnologiesPage(): JSX.Element {
  const [t, i18n] = useTranslation();

  const [videoPopupProps, setVideoPopupProps] =
    useState<VideoPopupProps | null>(null);

  const showVideo = (video: Videos) => {
    switch (video) {
      case Videos.Brewing:
        setVideoPopupProps({
          src: t("Technologies.Brewing.Video"),
          title: t("Technologies.Brewing"),
        });
        return;
      case Videos.Grinder:
        setVideoPopupProps({
          src: t("Technologies.Grinding.Video"),
          title: t("Technologies.Grinding"),
        });
        return;
      case Videos.MilkSystem:
        setVideoPopupProps({
          src: t("Technologies.Milk.Video"),
          title: t("Technologies.Milk"),
        });
        return;
      case Videos.Preparation:
        setVideoPopupProps({
          src: t("Technologies.Preparation.Video"),
          title: t("Technologies.Preparation"),
        });
        return;
      case Videos.WaterSystem:
        setVideoPopupProps({
          src: t("Technologies.Claris.Video"),
          title: t("Technologies.Claris"),
        });
        return;
    }
  };

  const hideVideo = () => {
    setVideoPopupProps(null);
  };

  const body = (
    <div className="h-full w-full relative bg-jura-grey-40">
      {/* Title */}
      <div className="ml-40 pt-16">
        <div className=" text-headline-1 text-jura-brown">
          {t("Technologies.Title")}
        </div>
        <div className=" text-headline-5 text-black w-[32rem]">
          {t("Technologies.Text")}
        </div>
      </div>
      {/* Technologies */}
      <div className=" h-4/5 absolute bottom-0 right-80 ">
        {/* How a Coffee machine works  */}
        <div className="absolute bottom-[87%] right-[2%]">
          <TechnologyButton onClick={() => showVideo(Videos.Preparation)} />
        </div>
        {/* Grinder */}
        <div className="absolute bottom-[50%] right-[22%]">
          <TechnologyButton onClick={() => showVideo(Videos.Grinder)} />
        </div>
        {/* Water  */}
        <div className="absolute bottom-[6%] right-[37%]">
          <TechnologyButton onClick={() => showVideo(Videos.WaterSystem)} />
        </div>
        {/* Brewing  */}
        <div className="absolute bottom-[33%] right-[48%]">
          <TechnologyButton onClick={() => showVideo(Videos.Brewing)} />
        </div>

        {/* Milk */}
        <div className="absolute bottom-[43%] right-[85%]">
          <TechnologyButton onClick={() => showVideo(Videos.MilkSystem)} />
        </div>
        <img className="h-[100%]" src={TechnologyBackground} />
      </div>
      {/* Video overlay */}
      {videoPopupProps == null ? null : (
        <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center bg-white p-10">
          <VideoPopup
            onClose={() => {
              hideVideo();
            }}
            src={
              "https://jura-storage.fra1.cdn.digitaloceanspaces.com/pos-tablet-website/videos/" +
              videoPopupProps!.src +
              ".mp4"
            }
            title={videoPopupProps!.title}
          />
        </div>
      )}
    </div>
  );
  // Enable the activity timeout when no video is playing (video props are null)
  return (
    <DefaultScaffold body={body} timeoutEnabled={videoPopupProps == null} />
  );
}

function TechnologyButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <div
      onClick={() => onClick()}
      className="h-14 w-14 transition hover:scale-105 text-white text-4xl bg-jura-red flex justify-center items-center rounded-full"
    >
      +
    </div>
  );
}

function VideoPopup({
  src,
  title,
  onClose,
}: {
  src: string;
  title: string;
  onClose: () => void;
}) {
  const [t, i18n] = useTranslation();
  const id = "video-id";
  useDetectClickOutside("video-id", () => {
    onClose();
  });
  return (
    <div
      id={id}
      className="p-20 pt-10 bg-jura-grey-40 rounded-lg relative grid h-full"
      style={{ gridTemplateRows: "auto minmax(0,1fr)" }}
    >
      <div className="text-jura-grey-80 text-headline-3 pb-6">{t(title)}</div>
      <div className="h-full aspect-video">
        {/* <img src={LoadingPoster} className="h-full aspect-video"></img> */}
        <video
          poster={LoadingPoster}
          className="h-full"
          src={t(src)}
          autoPlay
          muted
          onEnded={() => onClose()}
        />
      </div>

      <div
        className="material-symbols-outlined absolute top-4 right-4 text-6xl text-black aspect-video"
        onClick={() => onClose()}
      >
        close
      </div>
    </div>
  );
}
export default TechnologiesPage;
