import { useContext, useEffect, useRef, useState } from "react";
import { GreyButton } from "../Button/Button";
import useDetectClickOutside from "../ClickOutsideDetector/ClickOutsideDetector";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Pages/AppRoutes";
import BannerImage from "./jura-live-banner.jpg";
import ProjectIcon from "../ProjectIcon/ProjectIcon";
import { useJuraLive } from "../../JuraLive/JuraLive";
import { AppDependencies, AppDependencyContext } from "../..";
import { useTranslation } from "react-i18next";

interface SiteKioskConfig {
  languageCode: string;
  countryCode: string;
}
interface JuraLiveStatus {
  isOnline: boolean;
}

interface JuraLiveUrls {
  connectionUrl: string;
  statusUrl: string;
}

interface JuraLiveStatusResponse {
  status: string;
}

function JuraLiveSlider(): JSX.Element {
  const [t, i18n] = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const id = "jura.juraLiveSlider";

  const dependencies = useContext(AppDependencyContext);
  const juraLiveState = useJuraLive(dependencies!.juraLive);

  useDetectClickOutside(id, () => {
    setExpanded(false);
  });

  const navigate = useNavigate();

  return (
    <div
      id={id}
      className={[
        "flex flex-row group mt-20",
        juraLiveState.isOnline ? "" : "hidden",
      ].join(" ")}
    >
      {/* Visible part */}
      <div
        className="flex flex-col items-center justify-center bg-jura-live-green text-white text-body py-16 px-6 cursor-pointer relative"
        onClick={() => {
          //setExpanded(true);
          navigate(AppRoutes.JuraLive_CallOptions);
        }}
      >
        <div
          className="rotate-180 font-bold text-white"
          style={{ writingMode: "vertical-rl" }}
        >
          {t("Menu.JuraLive")}
        </div>
        <div className="h-4"></div>
        <div className="-rotate-90 h-5" style={{ writingMode: "vertical-rl" }}>
          <ProjectIcon icon={"people"} />
        </div>
      </div>
      {/* Hidden Part */}
      {/* <div
        className={[
          "bg-jura-grey-40 transition-all overflow-hidden",
          expanded ? "w-80" : "w-0", //"w-0 group-hover:w-80",
        ].join(" ")}
      >
        <div className="w-80 flex flex-col h-full">
          <div className="h-28">
            <img
              className="h-full w-full object-cover object-top"
              src={BannerImage}
            ></img>
          </div>
          <div className="flex-1 text-jura-grey-60 p-4 font-arial-light">
            Finden Sie den zu Ihnen passenden Kaffeevollautomaten
          </div>
          <div className="p-4">
            <GreyButton
              text="Jetzt beraten lassen"
              onClick={() => navigate(AppRoutes.JuraLive)}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default JuraLiveSlider;
