import { useEffect, useState } from "react";
import { ISiteKiosk, SiteKiosk, SiteKioskConfig } from "../SiteKiosk/SiteKiosk";

/**
 * A class which monitors the JURA Live state and provides methods to subscribe to state changes.
 * This class holds a reference to a ISiteKiosk object which provides it with information about
 * location, language etc.
 */
export class JuraLive {
  private _subs: ((status: JuraLiveState) => void)[] = [];
  private _timer: any;
  private _isOnline: boolean = false;

  private _connectionUrl: string | undefined;
  private _statusUrl: string | undefined;
  private _siteKiosk: ISiteKiosk;
  private _siteKioskConfig: SiteKioskConfig | undefined;
  private _baseUrl: string;

  constructor(refreshRateMs: number, siteKiosk: ISiteKiosk, baseUrl: string) {
    this._siteKiosk = siteKiosk;
    this._siteKioskConfig = this._siteKiosk.loadConfiguration();
    this._baseUrl = baseUrl;
    //Immediately get data, then refresh periodically
    this._updateAndNotify().then(() => {
      //Then refresh in an interval
      this._timer = setInterval(
        (() => {
          this._updateAndNotify();
        }).bind(this),
        refreshRateMs
      );
    });
  }

  // Subscribe to changes
  public subscribe(callback: (status: JuraLiveState) => void) {
    //Add the callback to the subscriber list
    this._subs.push(callback);
  }

  // Unsubscribe to changes
  public unsubscribe(callback: (status: JuraLiveState) => void) {
    //Find the element to be removed
    let index = this._subs.indexOf(callback);
    //If none is found, return
    if (index == -1) {
      return;
    }
    //Remove the element
    this._subs.splice(index, 1);
  }

  // Get the current state
  public get state(): JuraLiveState {
    return {
      connectionUrl: this._connectionUrl,
      statusUrl: this._statusUrl,
      isOnline: this._isOnline,
    };
  }

  // Notifies all subscribers of changes
  private _notify() {
    console.log(`JuraLive:\n${JSON.stringify(this.state)}`);

    this._subs.forEach((callback) => callback(this.state));
  }

  // Refresh the status of Jura Live
  // - Reload the Sitekiosk configuration
  // - Update the status and connections URLS
  private async _update(): Promise<void> {
    //load config from Sitekiosk
    //const sitekioskConfig = this._siteKiosk.loadConfiguration();

    //if sitekiosk config is not available
    if (this._siteKioskConfig == undefined) {
      this._connectionUrl = undefined;
      this._statusUrl = undefined;
      this._isOnline = false;
      return;
    }

    //get the urls for the current localization settings
    const urls = this._getJuraLiveConfiguration(
      this._siteKioskConfig.language,
      this._siteKioskConfig.country
    );

    //if no urls available (country and language not supported)
    if (urls == undefined) {
      this._connectionUrl = undefined;
      this._statusUrl = undefined;
      this._isOnline = false;
      return;
    }

    //get the status
    const status = await this._getLocalizedJuraLiveStatus(urls.statusUrl);
    this._connectionUrl = urls.connectionUrl;
    this._statusUrl = urls.statusUrl;
    this._isOnline = status.isOnline;
  }

  // Gets the latest data and notifies all subscribers
  private async _updateAndNotify() {
    let oldConnectionUrl = this._connectionUrl;
    let oldStatusUrl = this._statusUrl;
    let oldIsOnline = this._isOnline;

    await this._update();

    //notify change only if something has actually changed!
    if (
      this._connectionUrl != oldConnectionUrl ||
      this._statusUrl != oldStatusUrl ||
      this._isOnline != oldIsOnline
    ) {
      this._notify();
    }
  }

  // Gets the localized urls to interface with Jura Live
  private _getJuraLiveConfiguration(
    languageCode: string,
    countryCode: string
  ): JuraLiveConfiguration | undefined {
    let connectionUrl: undefined | string;
    let statusUrl: undefined | string;

    connectionUrl = `${this._baseUrl}/pos/?origin=pos&mute=false&fullscreen=false&country=${countryCode}&language=${languageCode}`;
    statusUrl = `${this._baseUrl}?origin=pos&country=${countryCode}&language=${languageCode}&status=json`;

    if (connectionUrl == undefined || statusUrl == undefined) {
      return undefined;
    }

    return {
      connectionUrl: connectionUrl,
      statusUrl: statusUrl,
    };
  }

  // Checks the status of the localized Jura Live System
  private async _getLocalizedJuraLiveStatus(
    statusUrl: string
  ): Promise<JuraLiveStatus> {
    try {
      let response = await fetch(statusUrl);

      let data = (await response.json()) as JuraLiveStatusResponseDTO;

      if (data.status != "online") {
        return {
          isOnline: false,
        };
      }

      return {
        isOnline: true,
      };
    } catch (e) {
      return {
        isOnline: false,
      };
    }
  }
}

// The Localized system specific configuration (urls)
interface JuraLiveConfiguration {
  connectionUrl: string;
  statusUrl: string;
}

//DTO for the data received from the status endpoint
interface JuraLiveStatusResponseDTO {
  status: string;
}

//The status of a Jura Live system
interface JuraLiveStatus {
  isOnline: boolean;
}

/**
 *  Custom hook to subscribe to changes in the Jura Live state
 * Triggers a rerender when the jura live state changes
 */
export function useJuraLive(juraLive: JuraLive): JuraLiveState {
  const [status, setStatus] = useState<JuraLiveState>(juraLive.state);

  useEffect(() => {
    const callback = (status: JuraLiveState) => {
      setStatus(status);
    };

    //subscribe to changes
    juraLive.subscribe(callback);

    //unsubscribe on dismount
    return () => {
      juraLive.unsubscribe(callback);
    };
  }, []);

  return status;
}

//Custom hook to subscribe to change in the Jura Live status
export function useJuraLiveEvent(
  juraLive: JuraLive,
  callback: (state: JuraLiveState) => void
) {
  useEffect(() => {
    //subscribe to changes
    juraLive.subscribe(callback);

    //unsubscribe on dismount
    return () => {
      juraLive.unsubscribe(callback);
    };
  }, []);
}

interface JuraLiveState {
  connectionUrl: string | undefined;
  statusUrl: string | undefined;
  isOnline: boolean;
}
