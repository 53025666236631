import exp from "constants";
import { Children, PropsWithChildren, useContext, useState } from "react";
import AppRoutes from "../../Pages/AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import useDetectClickOutside from "../ClickOutsideDetector/ClickOutsideDetector";
import Hamburger, { HamburgerState } from "../Hamburger/Hamburger";
import { AppDependencyContext } from "../..";
import { useJuraLive } from "../../JuraLive/JuraLive";
import { useTranslation } from "react-i18next";
import Juraify from "../Juraify/Juraify";

function DropdownMenu(): JSX.Element {
  // Not the most elegant solution, you have to match the checks here with the checks in the <MenuGroup> elements below
  // Manageable because only AboutJura has any subpages anyway
  const useInitialMenuIndex = () => {
    const location = useLocation();
    if (location.pathname.startsWith(AppRoutes.About)) {
      return 0;
    }

    return null;
  };

  const dependencies = useContext(AppDependencyContext)!;
  const juraLive = useJuraLive(dependencies.juraLive);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menuIndex, setMenuIndex] = useState<number | null>(
    useInitialMenuIndex()
  );

  const id = "jura.dropdownmenu";

  useDetectClickOutside(id, () => {
    setIsOpen(false);
  });

  const [t, i18n] = useTranslation();

  const handlePress = (index: number) => {
    setMenuIndex((v) => {
      //Menu is open, close it
      if (menuIndex == index) {
        return null;
      }
      //No menu is open, open this index
      else if (menuIndex == null) {
        return index;
      }
      //Another menu is open, open this one instead
      else {
        return index;
      }
    });
  };
  return (
    <div className="relative" id={id}>
      <div
        onClick={() => {
          setIsOpen((v) => !v);
        }}
        className=" text-jura-grey-80 cursor-pointer select-none relative w-10 h-10"
      >
        <div className="grid place-items-center h-full">
          <Hamburger
            state={isOpen ? HamburgerState.Cross : HamburgerState.Hamburger}
          />
        </div>
      </div>
      <div
        className={[
          "absolute  -bottom-9 right-0 w-[35rem] translate-y-full z-10",
          isOpen ? "" : "hidden",
        ].join(" ")}
      >
        {/* The Triangle */}
        <div className="flex justify-end px-2">
          <svg className="w-[25px] h-[20px] absolute top-0 -translate-y-full fill-white text-jura-grey-80">
            <path d="M 0,20 12.5,0 25,20 z" />
          </svg>
        </div>

        {/* The Dropdown body */}
        <div className="bg-white shadow-lg rounded-sm flex flex-col py-10">
          <MenuGroup
            name={t("Menu.AboutJura")}
            expanded={menuIndex == 0}
            route={AppRoutes.About}
            onPressed={() => handlePress(0)}
          >
            <MenuItem
              name={t("About.History.ListTitle")}
              route={AppRoutes.About_History}
            />
            <MenuItem
              name={t("About.Roger.ListTitle")}
              route={AppRoutes.About_RogerFederer}
            />
            <MenuItem
              name={t("About.Quality.ListTitle")}
              route={AppRoutes.About_CoffeeQuality}
            />
            <MenuItem
              name={t("About.Design.ListTitle")}
              route={AppRoutes.About_Design}
            />
            <MenuItem name="Service" route={AppRoutes.About_Service} />
            <MenuItem
              name={t("About.Sustainability.ListTitle")}
              route={AppRoutes.About_Sustainability}
            />
          </MenuGroup>
          {/* If Jura Live is not available (not just offline) hide the menu group */}
          {juraLive.connectionUrl != undefined ? (
            <MenuGroup
              name={t("Menu.JuraLive")}
              expanded={menuIndex == 1}
              onPressed={() => handlePress(1)}
              route={AppRoutes.JuraLive}
            ></MenuGroup>
          ) : (
            <></>
          )}
          <MenuGroup
            name={t("Menu.Technologies")}
            expanded={menuIndex == 3}
            route={AppRoutes.Technologies}
            onPressed={() => handlePress(3)}
          ></MenuGroup>
          <MenuGroup
            name={t("Menu.Joe")}
            expanded={menuIndex == 4}
            route={AppRoutes.Joe}
            onPressed={() => handlePress(4)}
          ></MenuGroup>
          <MenuGroup
            name={t("Menu.Quiz")}
            expanded={menuIndex == 5}
            route={AppRoutes.CoffeeExpertQuiz}
            onPressed={() => handlePress(4)}
          ></MenuGroup>
        </div>
      </div>
    </div>
  );
}

function MenuGroup({
  name,
  expanded,
  onPressed,
  route,
  children,
}: PropsWithChildren<{
  name: string;
  expanded: boolean;
  route?: string;
  onPressed: () => void;
}>) {
  const navigate = useNavigate();
  const location = useLocation();

  const isActiveRoute =
    route != undefined &&
    (route == location.pathname || location.pathname.startsWith(route));

  return (
    <div
      className={[
        "flex flex-col px-10 py-2 hover:brightness-95",
        expanded || isActiveRoute ? "border-t border-b bg-gray-50" : "bg-white",
      ].join(" ")}
    >
      <div className="flex flex-row  items-center">
        {/* The name */}
        <div
          className={[
            "text-body text-jura-grey-80 flex-1 ",
            expanded || isActiveRoute ? "font-arial-bold" : "font-arial",
            route == undefined ? "" : "cursor-pointer",
          ].join(" ")}
          onClick={() => {
            if (route != undefined) {
              navigate(route);
            }
          }}
        >
          <Juraify text={name} />
        </div>
        {/* The arrow */}
        <div
          className={[
            "material-symbols-outlined cursor-pointer select-none text-body text-jura-grey-80 transition",
            // expanded ? "-scale-y-100" : "",
            expanded ? " rotate-180" : "",
            children == undefined ? "hidden" : "",
          ].join(" ")}
          onClick={() => onPressed()}
        >
          expand_more
        </div>
      </div>
      <Expandable expanded={expanded}>
        <Indent>{children}</Indent>
      </Expandable>
    </div>
  );
}

function MenuItem({
  name,
  route,
  children,
}: PropsWithChildren<{ name: string; route?: string }>) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isActiveRoute = route == location.pathname && route != undefined;

  return (
    <div className="flex flex-col my-2">
      <div className="flex flex-row ">
        {/* The name */}
        <div
          className={[
            "text-headline-6 text-jura-grey-80  flex-1",
            isActiveRoute ? " font-arial-bold" : "font-arial",
            route == undefined ? "" : "cursor-pointer",
          ].join(" ")}
          onClick={() => {
            if (route != undefined) {
              navigate(route);
            }
          }}
        >
          <Juraify text={name} />
        </div>
        {/* The arrow */}
        <div
          className={[
            "material-symbols-outlined  cursor-pointer select-none",
            expanded || isActiveRoute ? "-scale-y-100" : "",
            children == undefined ? "hidden" : "",
          ].join(" ")}
          onClick={() => setExpanded((v) => !v)}
        >
          expand_more
        </div>
      </div>
      <Expandable expanded={expanded || isActiveRoute}>{children}</Expandable>
    </div>
  );
}

function Indent({ children }: PropsWithChildren) {
  return <div className="ml-2">{children}</div>;
}
function Expandable({
  expanded,
  children,
}: PropsWithChildren<{ expanded: boolean }>): JSX.Element {
  return (
    <div
      className={[
        "grid grid-rows-[0fr] transition-all",
        expanded ? "grid-rows-[1fr]" : "",
      ].join(" ")}
    >
      <div
        className={[
          "overflow-hidden transition-all",
          expanded ? "opacity-100" : "opacity-0",
        ].join(" ")}
      >
        <Indent>{children}</Indent>
      </div>
    </div>
  );
}

export default DropdownMenu;
