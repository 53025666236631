import { useNavigate } from "react-router-dom";
import DropdownMenu from "../../../Components/DropdownMenu/DropdownMenu";
import JuraLogo from "../../../Components/JuraLogo/JuraLogo";
import LanguagePicker from "../../../Components/LanguagePicker/LanguagePicker";
import AppRoutes from "../../AppRoutes";
import Hamburger from "../../../Components/Hamburger/Hamburger";
import HomeButton from "../../../Components/HomeButton/HomeButton";

function TopMenu(): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className="bg-white flex flex-row justify-between py-4 px-10 items-center">
      {/* left aligned elements */}
      <div
        className="h-20 cursor-pointer"
        onClick={() => navigate(AppRoutes.Front)}
      >
        <JuraLogo />
      </div>
      {/* right aligned elements */}
      <div className="flex flex-row items-center w-min">
        <HomeButton />
        <div className="w-12"></div>
        <LanguagePicker />
        <div className="w-12"></div>
        <DropdownMenu />
      </div>
    </div>
  );
}

export default TopMenu;
