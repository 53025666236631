import { useNavigate } from "react-router-dom";
import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";
import AppRoutes from "../../AppRoutes";

function CoffeeMachinesPage() {
  const navigate = useNavigate();

  var body = (
    <div className="flex flex-col  overflow-auto mb-20">
      <div className="px-32">
        <div className="text-headline-1 text-jura-brown mb-5">
          Kaffeevollautomaten
        </div>
        <div className="text-body text-black">
          Kaffeevollautomaten von JURA stehen für beste Kaffeequalität, einfache
          Bedienung und herausragendes Design. Geniessen Sie mit JURA eien
          Viuelzahl von Kaffeespezialiäten auf Knopfdruck - stets frisch
          gemahlen, nicht gekapselt.
        </div>
        <div className="h-20 bg-red-100">FILTER</div>
      </div>

      <div className="flex-1 grid grid-cols-4 grid-flow-row gap-5 mt-5 bg-jura-grey-20 px-32 py-16">
        <ProductCard
          title="GIGA 10"
          imgSrc="asdffsd"
          onClick={() => {
            navigate(AppRoutes.Products_Product);
          }}
        />
        <ProductCard title="asdfsadf" imgSrc="asdffsd" />
        <ProductCard title="asdfsadf" imgSrc="asdffsd" />
        <ProductCard title="asdfsadf" imgSrc="asdffsd" />
        <ProductCard title="asdfsadf" imgSrc="asdffsd" />
      </div>
    </div>
  );

  return <DefaultScaffold body={body} timeoutEnabled={false} />;
}

function ProductCard({
  title,
  imgSrc,
  onClick,
}: {
  title: string;
  imgSrc: string;
  onClick?: () => void;
}) {
  return (
    <div
      className="grid h-[30rem] bg-white"
      style={{ gridTemplateRows: "1fr 150px" }}
      onClick={() => onClick?.()}
    >
      <div>{imgSrc}</div>
      <div className="p-6">
        <div className="text-headline-4 font-arial-light text-jura-brown">
          {title}
        </div>
        <div className="flex flex-row items-center gap-5">
          <CheckBox />
          <div className="font-arial-light text-black text-headline-6">
            Vergleichen
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckBox() {
  return (
    <div className="h-8 w-8 bg-white border-2 border-jura-grey-80 rounded-lg"></div>
  );
}

export default CoffeeMachinesPage;
