import { useNavigate } from "react-router-dom";
import Button, { GreyButton } from "../../../Components/Button/Button";
import ProjectIcon from "../../../Components/ProjectIcon/ProjectIcon";
import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";
import Scaffold from "../../Shared/Scaffold/Scaffold";
import TopMenu from "../../Shared/TopMenu/TopMenu";
import AppRoutes from "../../AppRoutes";
import QRCode from "react-qr-code";
import { useJuraLive, useJuraLiveEvent } from "../../../JuraLive/JuraLive";
import { AppDependencyContext } from "../../..";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function CallOptionsPage() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  const appDependencies = useContext(AppDependencyContext);
  const juraLiveStatus = useJuraLive(appDependencies!.juraLive);

  var body = (
    <div className="h-full w-full grid grid-rows-3">
      <div className="px-20 text-jura-brown text-headline-1  mb-6 row-span-1 pt-10">
        {t("CallOptions.Title")}
      </div>
      <div className="border-t  px-20 grid grid-cols-2 row-span-2">
        <div className="py-20 px-20 border-r">
          <div className="text-4xl text-black  mb-6">
            {t("CallOptions.TabletTitle")}
          </div>
          <div className="text-xl text-black">
            {t("CallOptions.TabletText")}
          </div>

          {/* Button */}
          <div
            className="h-20 w-80 bg-jura-live-green text-white place-items-center rounded-lg flex flex-row justify-center items-center mt-10"
            onClick={() => navigate(AppRoutes.JuraLive_CallDelay)}
          >
            <div className="h-5 w-5">
              <ProjectIcon icon={"people"} />
            </div>
            <div className="w-4"></div>
            <div>{t("JuraLive.GetConsultation")}</div>
          </div>
        </div>
        <div
          className="flex-1 py-20 px-20 grid"
          style={{ gridTemplateRows: "auto auto 1fr" }}
        >
          <div className="text-4xl text-black mb-6">
            {t("CallOptions.PhoneTitle")}
          </div>
          <div className="text-xl text-black">{t("CallOptions.PhoneText")}</div>
          {juraLiveStatus.connectionUrl != undefined ? (
            <div className="relative mt-10 h-full">
              <QRCode
                className="absolute h-full w-min"
                value={juraLiveStatus.connectionUrl}
              />
            </div>
          ) : (
            <div className="text-body text-black">
              Aktuell sind keine Berater verfügbar
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return <DefaultScaffold body={body} showJuraLiveBanner={false} />;
}

export default CallOptionsPage;
