import { ISiteKiosk, SiteKioskConfig } from "../SiteKiosk/SiteKiosk";

class MockSiteKiosk implements ISiteKiosk {
  loadConfiguration(): SiteKioskConfig | undefined {
    return {
      language: "de",
      country: "ch",
      posName: "TEST NAME",
    };
  }
}

export default MockSiteKiosk;
