import { useEffect } from "react";
import { ISiteKiosk, SiteKiosk } from "../SiteKiosk/SiteKiosk";
function JuraLiveCall({
  url,
  onCallEnded,
}: {
  url: string;
  onCallEnded?: () => void;
}) {
  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      //Check if the field close is available on the message data
      if (event.data.close) {
        onCallEnded?.();
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <iframe
      className="h-full w-full overflow-y-hidden"
      src={url}
      allow="geolocation;microphone;camera;"
      allowFullScreen
    ></iframe>
  );
}

export default JuraLiveCall;
