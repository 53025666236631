function Juraify({ text }: { text: string }) {
  var lastIndex = 0;
  var elements = [];

  while (true) {
    let index = text.indexOf("®", lastIndex);
    console.log(index);
    if (index == -1) {
      elements.push(<>{text.substring(lastIndex)}</>);
      break;
    } else {
      elements.push(<>{text.substring(lastIndex, index)}</>);
      elements.push(<sup>{"®"}</sup>);
      lastIndex = index + 1;
    }
  }
  return <>{elements.map((e) => e)}</>;
}

export default Juraify;
