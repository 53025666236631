export interface SiteKioskConfig {
  language: string;
  country: string;
  posName: string;
}

export class SiteKiosk implements ISiteKiosk {
  private static _hasInitialised: boolean = false;

  constructor() {}

  loadConfiguration(): SiteKioskConfig | undefined {
    try {
      const machineName = this._getMachineName();

      if (machineName == undefined) {
        throw "No Sitekiosk configuration loaded";
      }

      const parts = this._splitMachineName(machineName);
      const languageCode = parts[1].toLowerCase();
      const countryCode = parts[0].toLowerCase();
      const posName = parts[2];

      if (
        languageCode == undefined ||
        posName == undefined ||
        countryCode == undefined
      ) {
        throw "Invalid Sitekiosk configuration";
      }

      return {
        language: languageCode,
        country: countryCode,
        posName: posName,
      };
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  /**
   * Method must be called before using any sitekiosk object
   */
  static async init(): Promise<void> {
    //Checks if siteKiosk class is already available
    if ((window as Object).hasOwnProperty("siteKiosk") == false) {
      //Create new script element
      var script = document.createElement("script");

      //Create new promise that waits for a script to load
      var promise = new Promise<void>((resolve, reject) => {
        script.addEventListener("load", () => {
          resolve();
        });
        script.addEventListener("error", () => {
          reject();
        });
      });

      //Add the script element to the document
      script.type = "text/javascript";
      script.src = "sk:///siteKiosk/siteKiosk.js";
      document.body.appendChild(script);

      //Await the script loading
      await promise;
    }
  }

  private _getMachineName(): string | undefined {
    try {
      let machineName = "";

      (window as any).siteKiosk.siteRemote.blackboard
        .getAll("StC.MachineInfo.Name")
        .forEach((e: any) => {
          machineName = e.value;
        });

      return machineName;
    } catch (e) {
      return undefined;
    }
  }

  private _splitMachineName(machineName: string): string[] {
    let parts = machineName.split("#");
    for (var i in parts) {
      parts[i] = parts[i].trim();
    }
    return parts;
  }
}

export interface ISiteKiosk {
  loadConfiguration: () => SiteKioskConfig | undefined;
}
