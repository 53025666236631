import { time } from "console";
import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";

class ActivityMonitorViewmodel {
  static ids: number = 0;

  private timeout!: NodeJS.Timeout;
  private didTimeout: boolean = false;
  private onTimeout: () => void;
  private timeoutDuration: number;
  id: number;

  constructor(onTimeout: () => void, timeoutDuration: number) {
    this.id = ActivityMonitorViewmodel.ids++;
    console.log("creating new activity monitor");
    this.onTimeout = onTimeout;
    this.timeoutDuration = timeoutDuration;
  }

  start() {
    console.debug(`starting ${this.id}`);
    this.timeout = setTimeout(
      this.handleTimeout.bind(this),
      this.timeoutDuration
    );
  }

  reset(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      this.handleTimeout.bind(this),
      this.timeoutDuration
    );
  }

  stop(): void {
    console.debug(`stopping ${this.id}`);
    clearTimeout(this.timeout);
  }

  private handleTimeout() {
    if (this.didTimeout) {
      console.log(`discarding timeout! ${this.id}`);
      return;
    }

    this.didTimeout = true;
    console.log(`handling timeout! ${this.id}`);
    this.onTimeout();
  }
}

function ActivityMonitor({
  timeoutMs,
  onTimeout,
  children,
}: PropsWithChildren<{
  timeoutMs: number;
  onTimeout: () => void;
}>) {
  useEffect(() => {
    //if timeout duration is negative this the activity monitor should be disabled!
    if (timeoutMs < 0) {
      return;
    }

    const vm = new ActivityMonitorViewmodel(onTimeout, timeoutMs);

    //vm.current.start();
    vm.start();

    const onClick = () => {
      //vm.current.reset();
      vm.reset();
    };
    const onScroll = () => {
      //vm.current.reset();
      vm.reset();
    };
    const onMouseMove = () => {
      //vm.current.reset();
      vm.reset();
      console.log("MOVE");
    };

    document.addEventListener("click", onClick, true);
    document.addEventListener("scroll", onScroll, true);
    document.addEventListener("mousemove", onMouseMove, true);

    return () => {
      //vm.current.stop();
      vm.stop();
      document.removeEventListener("click", onClick, true);
      document.removeEventListener("scroll", onScroll, true);
      document.removeEventListener("mousemove", onMouseMove, true);
    };
  }, [timeoutMs, onTimeout]);

  return <>{children}</>;
}

export default ActivityMonitor;
