import { useTranslation } from "react-i18next";
import useDetectClickOutside from "../../Components/ClickOutsideDetector/ClickOutsideDetector";
import ClickOutsideDetector from "../../Components/ClickOutsideDetector/ClickOutsideDetector";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import CallToAction from "./CallToAction";
import RogerImage from "./RogerImage";
import { useEffect, useState } from "react";
import { SiteKiosk, SiteKioskConfig } from "../../SiteKiosk/SiteKiosk";

function FrontPage(): JSX.Element {
  const [t, i18n] = useTranslation();

  const [state, setState] = useState<string | undefined>(undefined);

  let body = (
    <div className="h-full w-full to-[#D8D1CB] from-white bg-gradient-to-r relative">
      <div className="absolute bottom-0 left-40 h-5/6">
        <RogerImage />
      </div>
      <div className="absolute bottom-60 right-40  w-[40%]">
        <CallToAction
          title={t("FirstPage.H1")}
          subTitle={t("FirstPage.H2")}
          buttonRoute="/quiz"
          buttonText={t("FirstPage.CTA")}
        />
      </div>
    </div>
  );
  return <DefaultScaffold body={body} />;
}

export default FrontPage;
