import { useState } from "react";
import DefaultScaffold from "../Shared/Scaffold/DefaultScaffold";
import FlagItaly from "./images/flag_italy.png";
import FlagGermany from "./images/flag_germany.png";
import FlagFinland from "./images/flag_finland.png";
import FlagEthiopia from "./images/flag_ethiopia.png";
import FlagAlgeria from "./images/flag_algeria.png";
import FlagColumbia from "./images/flag_colombia.png";
import { GreyButton } from "../../Components/Button/Button";
import MedalFirst from "./images/medal_first.png";
import MedalSecond from "./images/medal_second.png";
import MedalThird from "./images/medal_third.png";

import Belt from "./images/belt.png";
import BeltEthiopia from "./images/belt_ethiopia.png";
import BeltEquator from "./images/belt_equator.png";

import BeanArabica from "./images/bean_arabica.png";
import BeanExcelsa from "./images/bean_excelsa.png";
import BeanLiberica from "./images/bean_liberica.png";
import ProjectIcon from "../../Components/ProjectIcon/ProjectIcon";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../AppRoutes";
import { useTranslation } from "react-i18next";

function CoffeeExpertQuizPage(): JSX.Element {
  const [t, i18n] = useTranslation();

  let body = <Quiz></Quiz>;

  return <DefaultScaffold body={body} timeoutEnabled={false} />;
}

export default CoffeeExpertQuizPage;

function Quiz(): JSX.Element {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  const [quizState, setQuizState] = useState<QuizState>(QuizState.InProgress);
  const [questionState, setQuestionState] = useState<QuestionState>(
    QuestionState.Asking
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [userAnswers, setUserAnswers] = useState<number[]>([]);
  const [questions, setQuestions] = useState<Question[]>([
    {
      question: "Question.1.Question",
      answers: [
        {
          imageSrc: FlagItaly,
          text: "Question.1.Answer.1",
        },
        { imageSrc: FlagGermany, text: "Question.1.Answer.2" },
        { imageSrc: FlagFinland, text: "Question.1.Answer.3" },
      ],
      correctAnswer: 2,
      answerDescription: "Question.1.CorrectText",

      correctAnswerText: "Question.1.Correct",
      wrongAnswerText: "Question.1.Wrong",
    },
    {
      question: "Question.2.Question",
      answers: [
        {
          imageSrc: FlagEthiopia,
          text: "Question.2.Answer.1",
        },
        { imageSrc: FlagAlgeria, text: "Question.2.Answer.2" },
        { imageSrc: FlagColumbia, text: "Question.2.Answer.3" },
      ],
      correctAnswer: 0,
      answerDescription: "Question.2.CorrectText",
      correctAnswerText: "Question.2.Correct",
      wrongAnswerText: "Question.2.Wrong",
    },
    {
      question: "Question.3.Question",
      answers: [
        {
          imageSrc: BeltEquator,
          text: "Question.3.Answer.1",
        },
        { imageSrc: Belt, text: "Question.3.Answer.2" },
        { imageSrc: BeltEthiopia, text: "Question.3.Answer.3" },
      ],
      correctAnswer: 1,
      answerDescription: "Question.3.CorrectText",
      correctAnswerText: "Question.3.Correct",
      wrongAnswerText: "Question.3.Wrong",
    },
    {
      question: "Question.4.Question",
      answers: [
        {
          imageSrc: BeanExcelsa,
          text: "Question.4.Answer.1",
        },
        { imageSrc: BeanLiberica, text: "Question.4.Answer.2" },
        { imageSrc: BeanArabica, text: "Question.4.Answer.3" },
      ],
      correctAnswer: 2,
      answerDescription: "Question.4.CorrectText",

      correctAnswerText: "Question.4.Correct",
      wrongAnswerText: "Question.4.Wrong",
    },
  ]);

  //Supply an answer to a quiz question
  const answerQuestion = (answerIndex: number) => {
    // Show correct or wrong answer
    if (answerIndex == questions[currentQuestionIndex].correctAnswer) {
      setQuestionState(QuestionState.AnsweredCorrect);
    } else {
      setQuestionState(QuestionState.AnsweredWrong);
    }

    // Store user answer
    setUserAnswers((old) => [...old, answerIndex]);
  };

  //Go to the next question
  const nextQuestion = () => {
    //Check if quiz is finished
    if (questions.length - 1 == currentQuestionIndex) {
      //Show resukt
      setQuizState(QuizState.Finished);
      return;
    }

    //Otherwise go to next question
    setQuestionState(QuestionState.Asking);
    setCurrentQuestionIndex((old) => old + 1);
  };

  //Restart the quiz
  const resetQuiz = () => {
    //Back to start
    setQuizState(QuizState.InProgress);
    setCurrentQuestionIndex(0);
    //Reset Answer state
    setQuestionState(QuestionState.Asking);
    //Clear answers
    setUserAnswers([]);
  };

  //Calculates the users score
  const getScore = () => {
    let score = 0;
    //Loop over every question and see if it matches the users supplied answer
    questions.forEach((question, index) => {
      if (question.correctAnswer == userAnswers[index]) {
        score++;
      }
    });
    return score;
  };

  const scoreTitles = [
    "Quiz.100.Title",
    "Quiz.75.Title",
    "Quiz.50.Title",
    "Quiz.25.Title",
    "Quiz.0.Title",
  ];

  const scoreDescriptions = [
    "Quiz.100.Text",
    "Quiz.75.Text",
    "Quiz.50.Text",
    "Quiz.25.Text",
    "Quiz.25.Text",
  ];

  return (
    <div className=" h-full">
      {(() => {
        switch (quizState) {
          case QuizState.InProgress:
            return (
              <div
                className="grid h-full"
                style={{ gridTemplateRows: "auto minmax(0,1fr) auto" }}
              >
                {/* Header */}
                <div className="flex flex-col items-center mb-10 mt-10">
                  <div className="text-headline-3 text-jura-brown">
                    {t("Quiz.Title")}
                  </div>
                  <div className="text-headline-4 text-jura-grey-80 font-arial-bold">
                    {t("Quiz.Text")}
                  </div>
                </div>
                {/* Content */}
                <div className="bg-jura-grey-20 flex flex-col justify-center align-center relative">
                  <div className="absolute top-0 w-full grid place-items-center">
                    <svg className="w-[50px] h-[30px]" fill="white">
                      <path d="M 0,0 25,30 50,0 z" />
                    </svg>
                  </div>
                  {(() => {
                    let question = questions[currentQuestionIndex];
                    switch (questionState) {
                      case QuestionState.Asking:
                        return (
                          <div className="flex flex-col items-center ">
                            <div className="text-headline-3 text-jura-grey-80 text-center mb-10 max-w-7xl ">
                              {t(question.question)}
                            </div>
                            <div
                              className="grid grid-flow-col  max-w-[100rem]"
                              style={{
                                gridTemplateRows: "minmax(0,1fr) auto",
                                gridColumnGap: "4rem",

                                gridTemplateColumns: "1fr 1fr 1fr",
                              }}
                            >
                              {question.answers.map((answer, index) => {
                                return (
                                  <>
                                    <div
                                      className="h-60 max-w-[25rem]  flex flex-row justify-center"
                                      onClick={() => answerQuestion(index)}
                                    >
                                      <img
                                        className="h-full object-contain"
                                        src={answer.imageSrc}
                                      />
                                    </div>

                                    <div
                                      className="text-headline-4 text-jura-grey-80 text-center max-w-[25rem] font-arial-bold  flex flex-row justify-center"
                                      onClick={() => answerQuestion(index)}
                                    >
                                      {t(answer.text)}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        );
                      case QuestionState.AnsweredCorrect:
                        return (
                          <div className="flex flex-col justify-center items-center">
                            <div className="h-10 w-10">
                              <ProjectIcon icon={"correct"} />
                            </div>
                            <div className="text-headline-3 text-center text-jura-grey-80 max-w-6xl  mb-6">
                              {t(question.correctAnswerText)}
                            </div>
                            {/* <div className="text-headline-6 text-jura-grey-60 font-arial-bold max-w-4xl text-center mb-6">
                              {t(question.answerDescription)}
                            </div> */}
                            <div className="h-60 w-80">
                              <img
                                className="h-full w-full object-contain"
                                src={
                                  question.answers[question.correctAnswer]
                                    .imageSrc
                                }
                              />
                            </div>
                            <div className="text-headline-4 text-jura-grey-80 text-center font-arial-bold">
                              {t(question.answers[question.correctAnswer].text)}
                            </div>
                          </div>
                        );
                      case QuestionState.AnsweredWrong:
                        return (
                          <div className="flex flex-col justify-center items-center">
                            <div className="h-10 w-10">
                              <ProjectIcon icon={"wrong"} />
                            </div>
                            <div className="text-headline-3 text-center text-jura-grey-80 max-w-6xl  mb-6">
                              {t(question.wrongAnswerText)}
                            </div>
                            {/* <div className="text-headline-6  text-jura-grey-60 font-arial-bold max-w-4xl text-center mb-6">
                              {t(question.answerDescription)}
                            </div> */}
                            <div className="h-60 w-80">
                              <img
                                className="h-full w-full object-contain"
                                src={
                                  question.answers[question.correctAnswer]
                                    .imageSrc
                                }
                              />
                            </div>
                            <div className="text-headline-4 text-jura-grey-80 text-center font-arial-bold">
                              {t(question.answers[question.correctAnswer].text)}
                            </div>
                          </div>
                        );
                    }
                  })()}
                </div>
                {/* Controls */}
                <div className=" bg-jura-grey-20 flex flex-row items-center justify-end px-40 pb-10 ">
                  {(() => {
                    switch (questionState) {
                      case QuestionState.AnsweredCorrect:
                      case QuestionState.AnsweredWrong:
                        return (
                          <div>
                            <GreyButton
                              text={t("Quiz.Next")}
                              onClick={() => nextQuestion()}
                            ></GreyButton>
                          </div>
                        );
                      default:
                        return <></>;
                    }
                  })()}
                </div>
              </div>
            );
          case QuizState.Finished:
            let score = getScore();
            return (
              <div
                onClick={() => resetQuiz()}
                className="pt-20 flex flex-col justify-center items-center h-full"
              >
                {/* The Result text */}
                <div className="text-headline-3 text-jura-brown text-center max-w-5xl">
                  {t(
                    (() => {
                      switch (score) {
                        case 0:
                          return scoreTitles[4];
                        case 1:
                          return scoreTitles[3];
                        case 2:
                          return scoreTitles[2];
                        case 3:
                          return scoreTitles[1];
                        case 4:
                          return scoreTitles[0];
                        default:
                          throw "";
                      }
                    })()
                  )}
                </div>
                <div className="text-headline-5 text-jura-grey-80 text-center font-arial-bold pt-10">
                  {t(
                    (() => {
                      switch (score) {
                        case 0:
                          return scoreDescriptions[4];
                        case 1:
                          return scoreDescriptions[3];
                        case 2:
                          return scoreDescriptions[2];
                        case 3:
                          return scoreDescriptions[1];
                        case 4:
                          return scoreDescriptions[0];
                        default:
                          throw "";
                      }
                    })()
                  )}
                </div>
                {/* The Medal */}
                <div className="h-80 w-80 mt-40">
                  <img
                    className="h-full w-full object-contain"
                    src={(() => {
                      switch (score) {
                        case 4:
                          return MedalFirst;
                        case 3:
                        case 2:
                          return MedalSecond;
                        case 1:
                        case 0:
                          return MedalThird;
                      }
                    })()}
                  />
                </div>
                {/* Back Button */}
                <div className="flex flex-row items-bottom justify-end px-40 pb-10 w-full">
                  <GreyButton
                    text={t("Quiz.Back")}
                    onClick={() => navigate(AppRoutes.Front)}
                  />
                </div>
                {/* <div className="flex-1 flex justify-end items-end w-full p-10"></div> */}
              </div>
            );
        }
      })()}
    </div>
  );
}

interface Question {
  question: string;
  answers: {
    imageSrc: string;
    text: string;
  }[];
  correctAnswer: number;
  answerDescription: string;
  correctAnswerText: string;
  wrongAnswerText: string;
}

enum QuestionState {
  Asking,
  AnsweredCorrect,
  AnsweredWrong,
}

enum QuizState {
  InProgress,
  Finished,
}
