import { useState } from "react";
import useDetectClickOutside from "../ClickOutsideDetector/ClickOutsideDetector";
import ProjectIcon from "../ProjectIcon/ProjectIcon";
import { useTranslation } from "react-i18next";

function LanguagePicker(): JSX.Element {
  const id = "language-picker";

  const [dropdownShowing, setDropdownShowing] = useState<boolean>(false);

  const showDropdown = () => {
    setDropdownShowing(true);
  };
  const hideDropdown = () => {
    setDropdownShowing(false);
  };
  useDetectClickOutside(id, () => {
    hideDropdown();
  });

  const [t, i18n] = useTranslation();

  const language = i18n.language;
  const languages = Object.keys(i18n.services.resourceStore.data);

  const selectLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    hideDropdown();
  };
  return (
    <div className="flex flex-row items-center">
      <div className="h-8 w-8" onClick={() => showDropdown()}>
        <ProjectIcon icon={"world"} />
      </div>
      <div className="w-3"></div>
      <div className="text-body relative text-jura-grey-80">
        <div
          className="font-arial-light uppercase"
          onClick={() => showDropdown()}
        >
          {language}
        </div>
        <div
          id={id}
          className={[
            "absolute flex flex-col z-20 bg-white py-4 left-1/2 -translate-x-1/2 mt-[40px] font-arial-light shadow-lg",
            dropdownShowing ? "" : "hidden",
          ].join(" ")}
        >
          {/* The Triangle */}
          <div className="flex justify-center">
            <svg className="w-[25px] h-[20px] absolute top-0 -translate-y-full fill-white text-jura-grey-80">
              <path d="M 0,20 12.5,0 25,20 z" />
            </svg>
          </div>
          {/* The list of languages */}
          {languages.map((lang) => {
            return (
              <div
                onClick={() => selectLanguage(lang)}
                key={lang}
                className={[
                  "hover:bg-jura-grey-20 px-7 py-3 uppercase",
                  lang == language ? "font-arial-bold" : "font-arial-light",
                ].join(" ")}
              >
                {lang}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LanguagePicker;
