import { useState } from "react";
import DefaultScaffold from "../../Shared/Scaffold/DefaultScaffold";

function ProductPage() {
  const body = (
    <div>
      {/* Header Banner */}
      <div className="w-full h-[70vh] bg-red-100"></div>
      {/* Back Navigation */}
      <div className="flex flex-row justify-start items-center px-40 bg-red-200">
        <div className="material-symbols-outlined">arrow_left_alt</div>
        <div>Zur Übersicht</div>
      </div>
      {/* Product Introduction */}
      <div className="flex flex-col justify-center items-center">
        <div className=" text-jura-brown font-arial-light text-headline-4">
          Z10
        </div>
        <div className="text-black font-arial-bold text-headline-5 mt-16">
          Beeindruckend in Mignight Blue
        </div>
        <div className="text-body font-arial text-black max-w-[1200px] mt-10">
          Sie ist, was sie seit Anbeginn war: ein Meisterwerk Schweizer
          Ingenieurkunst. Die Z10 offeriert klassischen Kaffeegenus und
          angesagte Neuheiten.Auch Cold Brew Spezialitäten bereitet sie auf
          Knopfdruck zu. Frisch gemahlen, nicht gekapselt - jedoch kalt
          extrahiert. Zwei schlüssentechnologien von JURA öffneten diese Tore
          zur vollautomatische Kaltextraktion und definierten Kaffeegenuss neu.
        </div>
        <div className="px-40 w-full">
          <ProductSlider></ProductSlider>
        </div>
        <div className="w-full">ENERGY EFFICIENCY</div>
      </div>
      {/* Product Details */}
      <div>
        <TabView
          tabs={[
            { content: <div>asdf 1</div>, title: "title 1" },
            { content: <div>asdf 2</div>, title: "title 2" },
          ]}
        ></TabView>
      </div>
    </div>
  );

  return <DefaultScaffold timeoutEnabled={false} body={body} />;
}

function ProductSlider() {
  const [index, setIndex] = useState(0);

  const left = () => {
    if (index == 1) {
      setIndex(0);
    }
  };
  const right = () => {
    if (index == 0) {
      setIndex(1);
    }
  };

  console.log(index);
  return (
    <div>
      <div
        className="grid  w-full"
        style={{ gridTemplateColumns: "auto 1fr auto" }}
      >
        {/* control left */}
        <div
          className="bg-red-300 flex justify-center items-center"
          onClick={() => left()}
        >
          <div className="material-symbols-outlined text-5xl">chevron_left</div>
        </div>
        {/* gallery */}
        <div className="bg-red-200 overflow-hidden relative h-[500px]">
          <div
            className={[
              "absolute h-full w-full bg-green-100 transition-all duration-1000",
              index > 0 ? "left-full" : index < 0 ? "-left-full" : "left-0",
            ].join(" ")}
          ></div>
          <div
            className={[
              "absolute h-full w-full bg-green-600 transition-all duration-1000",
              index > 1 ? "left-full" : index < 1 ? "-left-full" : "left-0",
            ].join(" ")}
          ></div>
        </div>
        {/* control right */}
        <div
          className="bg-red-300 flex justify-center items-center"
          onClick={() => right()}
        >
          <div className="material-symbols-outlined text-5xl">
            chevron_right
          </div>
        </div>
      </div>
      {/* dots */}
      <div className="flex flex-row justify-center items-center bg-red-100 gap-2">
        <div
          className={[
            "h-3 w-3 rounded-full transition-all duration-1000",
            index != 0 ? "bg-jura-grey-80" : "bg-black",
          ].join(" ")}
        ></div>
        <div
          className={[
            "h-3 w-3 rounded-full transition-all duration-1000",
            index != 1 ? "bg-jura-grey-80" : "bg-black",
          ].join(" ")}
        ></div>
      </div>
    </div>
  );
}

interface Tab {
  content: JSX.Element;
  title: string;
}
function TabView({ tabs }: { tabs: Tab[] }) {
  const [index, setIndex] = useState(0);

  const goToTab = (newIndex: number) => {
    setIndex(newIndex);
  };
  return (
    <div className="">
      {/* header */}
      <div className="flex flex-row justify-center gap-10">
        {tabs.map((tab, tabIndex) => (
          <div>
            <div onClick={() => goToTab(tabIndex)}>{tab.title}</div>
            <div
              className={[
                "h-1 transition-all duration-1000",
                tabIndex == index ? "bg-black" : "bg-transparent",
              ].join(" ")}
            ></div>
          </div>
        ))}
      </div>
      <div className="w-full border-b border-b-jura-grey-40 -translate-y-1 mx-10"></div>
      {/* body */}
      <div>{tabs[index].content}</div>
    </div>
  );
}
export default ProductPage;
