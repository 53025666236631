import FlagAlgeria from "./Icons/Algeria_Flag_Icon.png";
import Arabica from "./Icons/Arabica_Icon.png";
import CoffeeBeltEquator from "./Icons/Coffee_Belt_Equator.png";
import CoffeeBeltEthiopia from "./Icons/Coffee_Belt_Ethiopia.png";
import CoffeeBelt from "./Icons/Coffee_Belt.png";
import FlagColumbia from "./Icons/Colombia_Flag_Icon.png";
import Excelsa from "./Icons/Excelsa_Icon.png";
import FlagFinland from "./Icons/Finland_Flag_Icon.png";
import FlagGermany from "./Icons/Germany_Flag_Icon.png";
import Headset from "./Icons/Headset_Icon.png";
import FlagItaly from "./Icons/Italy_Flag_Icon.png";
import Liberica from "./Icons/Liberica_Icon.png";
import Medal1st from "./Icons/Medal_1st_Place_Icon.png";
import Medal2nd from "./Icons/Medal_2nd_Place_Icon.png";
import Medal3rd from "./Icons/Medal_3rd_Place_Icon.png";
import PhoneOrMessage from "./Icons/Phone_or_Message_Icon.png";
import Trophy from "./Icons/Pokal_Icon.png";
import ShoppingCart from "./Icons/Shopping_Cart_Icon.png";
import Touch from "./Icons/Touch_Icon.png";
import World from "./Icons/world-icon.png";
import People from "./Icons/people.png";
import Correct from "./Icons/icon_correct.png";
import Wrong from "./Icons/icon_wrong.png";
import House from "./Icons/House_Icon.png";
import ChevronLeft from "./Icons/Chevron_Left_Icon.png";

export type ProjectIconKind =
  | "flag_algeria"
  | "arabica"
  | "coffee_belt_equator"
  | "coffee_belt_ethiopia"
  | "coffee_belt"
  | "flag_columbia"
  | "excelsa"
  | "flag_finland"
  | "flag_germany"
  | "headset"
  | "flag_italy"
  | "liberica"
  | "medal_1st"
  | "medal_2nd"
  | "medal_3rd"
  | "phone_or_message"
  | "tropy"
  | "shopping_cart"
  | "touch"
  | "world"
  | "people"
  | "wrong"
  | "correct"
  | "home"
  | "chevron_left";

function ProjectIcon({ icon }: { icon: ProjectIconKind }) {
  const getImageSrc = (icon: ProjectIconKind) => {
    switch (icon) {
      case "arabica":
        return Arabica;
      case "coffee_belt_equator":
        return CoffeeBeltEquator;
      case "coffee_belt_ethiopia":
        return CoffeeBeltEthiopia;
      case "coffee_belt":
        return CoffeeBelt;
      case "flag_columbia":
        return FlagColumbia;
      case "excelsa":
        return Excelsa;
      case "flag_finland":
        return FlagFinland;
      case "flag_germany":
        return FlagGermany;
      case "flag_algeria":
        return FlagAlgeria;
      case "headset":
        return Headset;
      case "flag_italy":
        return FlagItaly;
      case "liberica":
        return Liberica;
      case "medal_1st":
        return Medal1st;
      case "medal_2nd":
        return Medal2nd;
      case "medal_3rd":
        return Medal3rd;
      case "phone_or_message":
        return PhoneOrMessage;
      case "tropy":
        return Trophy;
      case "shopping_cart":
        return ShoppingCart;
      case "touch":
        return Touch;
      case "world":
        return World;
      case "people":
        return People;
      case "wrong":
        return Wrong;
      case "correct":
        return Correct;
      case "home":
        return House;
      case "chevron_left":
        return ChevronLeft;
    }
  };
  return (
    <img className="h-full w-full object-contain" src={getImageSrc(icon)} />
  );
}

export default ProjectIcon;
