import { PropsWithChildren, useEffect } from "react";

/**
 * This hook can be used to detect a click outside of an html element, identified by its id.
 * @param id the id used to identify the element
 * @param onClickOutside the callback called when a click outside the element has been detected
 */
function useDetectClickOutside(id: string, onClickOutside: () => void) {
  useEffect(() => {
    let callback = (event: MouseEvent) => {
      let element = document.getElementById(id);

      var containsElement = element?.contains(event.target as HTMLElement);
      var isElement = (event.currentTarget as HTMLElement) == element;

      if (containsElement == false && isElement == false) {
        onClickOutside?.();
      } else {
        let foo = "";
      }
    };

    window.addEventListener("click", callback, true);

    return () => {
      document.removeEventListener("click", callback, true);
    };
  }, []);
}

export default useDetectClickOutside;
