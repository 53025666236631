import "./App.css";
import FrontPage from "./Pages/01_FrontPage/FrontPage";
import AboutJuraPage from "./Pages/02_AboutJura/AboutJuraPage";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import AppRoutes from "./Pages/AppRoutes";
import HistoryPage from "./Pages/02_AboutJura/02_01_History/HistoryPage";
import RogerFedererPage from "./Pages/02_AboutJura/02_02_RogerFederer/RogerFedererPage";
import CoffeeQualityPage from "./Pages/02_AboutJura/02_03_CoffeeQuality/CoffeeQualityPage";
import DesignPage from "./Pages/02_AboutJura/02_04_Design/DesignPage";
import ServicePage from "./Pages/02_AboutJura/02_05_Service/ServicePage";
import SustainabilityPage from "./Pages/02_AboutJura/02_06_Sustainability/SustainabilityPage";
import JuraLivePage from "./Pages/05_JuraLive/JuraLivePage";
import CallOptionsPage from "./Pages/05_JuraLive/05_01_CallOptions/CallOptionsPage";
import JoePage from "./Pages/06_Joe/JoePage";
import JoeDownloadPage from "./Pages/06_Joe/06_01_Download/JoeDownloadPage";
import CoffeeExpertQuizPage from "./Pages/07_CoffeeExpertQuiz/CoffeeExpertQuizPage";
import TechnologiesPage from "./Pages/04_Technologies/TechnologiesPage";
import CallDelayPage from "./Pages/05_JuraLive/05_02_CallDelay/CallDelayPage";
import CallPage from "./Pages/05_JuraLive/05_03_Call/CallPage";
import ProductsPage from "./Pages/03_Products/ProductsPage";
import CoffeeMachinesPage from "./Pages/03_Products/03_01_CoffeeMachines/CoffeeMachinesPage";
import ProductPage from "./Pages/03_Products/Product/ProductPage";

function App() {
  return (
    <div className=" h-screen w-screen">
      <BrowserRouter>
        <Routes>
          {/* Front */}
          <Route path={AppRoutes.Front} element={<FrontPage />}></Route>
          {/* About */}
          <Route path={AppRoutes.About}>
            <Route index={true} element={<AboutJuraPage />}></Route>
            <Route
              path={AppRoutes.About_History}
              element={<HistoryPage />}
            ></Route>
            <Route
              path={AppRoutes.About_RogerFederer}
              element={<RogerFedererPage />}
            ></Route>
            <Route
              path={AppRoutes.About_CoffeeQuality}
              element={<CoffeeQualityPage />}
            ></Route>
            <Route
              path={AppRoutes.About_Design}
              element={<DesignPage />}
            ></Route>
            <Route
              path={AppRoutes.About_Service}
              element={<ServicePage />}
            ></Route>
            <Route
              path={AppRoutes.About_Sustainability}
              element={<SustainabilityPage />}
            ></Route>
          </Route>
          {/* Products */}
          <Route path={AppRoutes.Products}>
            <Route index={true} element={<ProductsPage />}></Route>
            <Route
              path={AppRoutes.Products_CoffeeMachines}
              element={<CoffeeMachinesPage />}
            ></Route>
            <Route
              path={AppRoutes.Products_Product}
              element={<ProductPage />}
            ></Route>
          </Route>
          {/* JuraLive */}
          <Route path={AppRoutes.JuraLive}>
            <Route index={true} element={<JuraLivePage />}></Route>
            <Route
              path={AppRoutes.JuraLive_CallOptions}
              element={<CallOptionsPage />}
            ></Route>
            <Route
              path={AppRoutes.JuraLive_CallDelay}
              element={<CallDelayPage />}
            ></Route>
          </Route>
          <Route path={AppRoutes.JuraLive_Call} element={<CallPage />}></Route>
          {/* Joe */}
          <Route path={AppRoutes.Joe}>
            <Route index={true} element={<JoePage />}></Route>
            <Route
              path={AppRoutes.Joe_Download}
              element={<JoeDownloadPage />}
            ></Route>
          </Route>
          {/* Front */}
          <Route
            path={AppRoutes.CoffeeExpertQuiz}
            element={<CoffeeExpertQuizPage />}
          ></Route>
          {/* Technologies */}
          <Route
            path={AppRoutes.Technologies}
            element={<TechnologiesPage />}
          ></Route>

          {/* Default */}
          <Route path={"/"} element={<FrontPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
